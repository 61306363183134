<template>
  <main>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          >FRAMINGHAM</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#contact"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
          >TASA FILTRACIÓN GLOMERULAR</a
        >
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <indexFragmingam
          :idHistoria="idHistoria"
          :finalizada="finalizada"
          :idUsuario="idUsuario"
          :sexo="sexo"
          :fecha_nacimiento="fecha_nacimiento"
        ></indexFragmingam>
      </div>
      <div
        class="tab-pane fade"
        id="contact"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <indexFiltracion
          :idHistoria="idHistoria"
          :finalizada="finalizada"
          :idUsuario="idUsuario"
          :sexo="sexo"
          :fecha_nacimiento="fecha_nacimiento"
        ></indexFiltracion>
      </div>
    </div>
  </main>
</template>
<script>
import indexFragmingam from "../base/baseFragmingam/indexFragmingam";
import indexFiltracion from "../base/baseFiltracionGlomerula/index";
export default {
  props: ["idHistoria", "finalizada", "idUsuario", "sexo", "fecha_nacimiento"],
  components: { indexFragmingam, indexFiltracion },
};
</script>
